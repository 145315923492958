.navbar-brand img{
  width: 150px;
}
body{
  background-color: #FFFFFF94 !important;
}

*:focus {
  box-shadow: none !important;
}
.App-header.sticky-top {
  transition: all 0.5s;
  box-shadow: 0px 2px 15px rgb(0 0 0 / 22%);
}
.theme-color{
  color: #4629d2 !important;
}
.theme-color-2{
  color: #293241 !important;
}

#home{
  scroll-margin-top: 0;
}
#ourbusiness,
#whoweare,
#contact{
  scroll-margin-top: 80px;
}


.theme-color.nav-link{
  text-transform: uppercase;
  font-size: 15px;
  font-weight: 400;
}
body .bg-scroll .bg-body-tertiary{
  background-color: #fff !important;
  transition: all .3s ease-in-out !important;
  box-shadow: 0px 2px 5px 0px rgba(0,0,0,0.3);
}
.bg-scroll .navbar .nav-link{
  color: #004a55!important
}
.bg-scroll .navbar .get-started .nav-link{
  color: #fff !important;
}
.bg-scroll .bg-body-tertiary .navbar-brand{
  filter: none;
}
body .bg-body-tertiary{
  --bs-bg-opacity: 1;
  background-color: transparent !important;
}
.bg-scroll .button-navbar a:hover {
  background-image: linear-gradient(90deg,#004a55 0%,#4629d2 100%);
  transition: all .3s ease-in-out;
}

.offcanvas.offcanvas-end.show .theme-color.nav-link.item--active{
  color: #030017 !important;
}

.theme-color.nav-link{
  text-transform: uppercase !important;
}
.offcanvas.offcanvas-end[aria-modal="true"]  .get-started{
  display: none;
}
.button-navbar a{
  display: flex;
  align-items: center;
  width: 150px;
  justify-content: space-evenly;
  color: #FFF!important;
  border: 2px solid #fff;
  border-radius: 30px;
  font-size: 18px !important;
  font-weight: 700!important;
  text-transform: uppercase!important;
  background-image: linear-gradient(90deg,#004a55 0%,#4629d2 100%);
}
.navbar .nav-link{
  padding: 13px 20px !important;
  color: #fff !important;
  font-family: 'Archivo', sans-serif;
  text-transform: uppercase !important;
  font-weight: 600;
  font-size: 12px;
}
/* .item--active.nav-link{
  color: #F0A202 !important;
} */


/* start here  */

.navbar-brand{
  filter: hue-rotate(359deg) saturate(0%) brightness(0%) contrast(200%) invert(100%);
    backface-visibility: hidden;
    transition: filter 300ms ease 0ms;
    text-align: left;
    margin-left: 0;
}
.hero {
  position: relative;
  overflow: hidden;
}
.heroSection h1{
  max-width: max-content;
  margin: auto;
  padding: 20px;
  border: 5px solid #fff;
  font-weight: 800;
  text-transform: uppercase;
  font-size: 100px;
  color: #fff;
}
.heroSection .row{
  min-height: 700px;
}

.video-bg{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}
.video-bg video{
  width: 100%;
}

.hero p {
  font-weight: 700;
  font-size: 34px;
  color: #fff!important;
  text-align: center;
  text-shadow: 0em 0.1em 0.1em rgba(0,0,0,0.4);
}
.hero .btn-cs{
  color: #FFF!important;
  border: 2px solid #fff;
  border-radius: 30px;
  font-size: 22px;
  font-weight: 700!important;
  text-transform: uppercase!important;
  background-image: linear-gradient(90deg,#004a55 0%,#4629d2 100%);
  transition: all .3s ease-in-out;
}

.hero .btn-cs:hover{
  border: 2px solid #fff;
  background: transparent;
  transition: all .3s ease-in-out;
}
.button-navbar a:hover{
  background: transparent;
  transition: all .3s ease-in-out;
}

.divider{
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAwJSIgaGVpZ2h0PSIxMDBweCIgdmlld0JveD0iMCAwIDEyODAgMTQwIiBwcmVzZXJ2ZUFzcGVjdFJhdGlvPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPjxnIGZpbGw9IiNmZmZmZmYiPjxwYXRoIGQ9Ik02NDAgMTM5TDAgMHYxNDBoMTI4MFYwTDY0MCAxMzl6IiBmaWxsLW9wYWNpdHk9Ii41Ii8+PHBhdGggZD0iTTY0MCAxMzlMMCA0MnY5OGgxMjgwVjQybC02NDAgOTd6Ii8+PC9nPjwvc3ZnPg==);
  background-size: cover;
  bottom: 80px;
  height: 100px;
  z-index: 1;
  transform: scale(1,1);
  width: 100%;
}


/* our bysiness */

.ourbusiness {
  padding: 100px 0;
}

.ourbusiness h2 {
  color: #004a55!important;
  font-weight: 700;
  font-size: 51px;
  max-width: 70%;
  margin: auto;
  text-align: center;
  margin-bottom: 50px;
}
.ourbusiness .card{
  padding: 30px;
  border-radius: 20px;
  box-shadow: 0px 2px 5px 0px rgba(0,0,0,0.3);
  transition: box-shadow 300ms ease 0ms;
  border: 0;
  margin-bottom: 30px;
}

.ourbusiness .card:hover{
  box-shadow: 0px 2px 45px 0px rgba(0,0,0,0.3);
  transition: box-shadow 300ms ease 0ms;
}

.ourbusiness .card-title{
  font-weight: 700;
  font-size: 20px;
  text-align: center;
  color: #161616 !important;
  margin: 20px 0 10px;
}

.ourbusiness .card-text{
  font-family: 'Source Sans 3', sans-serif;
  font-size: 16px;
  text-align: center;
}



/* cta */

.cta{
  padding: 50px 0;
  background: #0c71c3!important;
  background-image: linear-gradient(169deg,rgba(7,64,178,0.9) 0%,rgba(62,136,221,0.9) 100%)!important;
}


.cta h2{
  color: #fff;
  font-weight: 800;
  font-size: 38px;
}

.cta .btn-cs{
  color: #FFF!important;
  border: 2px solid #fff;
  border-radius: 30px;
  font-size: 22px;
  font-weight: 700!important;
  text-transform: uppercase!important;
  background-image: linear-gradient(90deg,#004a55 0%,#4629d2 100%);
  transition: all .3s ease-in-out;
}

.cta .btn-cs:hover{
  border: 2px solid #fff;
  background: transparent;
  transition: all .3s ease-in-out;
}


/* WhoWeAre */

.WhoWeAre{
  padding: 100px 0;
  background: #f9f9f9;
}

.WhoWeAre h2{
  font-weight: 700;
  font-size: 30px;
  margin-bottom: 40px;
}
.WhoWeAre p{
  font-size: 16px;
  font-weight: 500;
  font-family: 'Source Sans 3', sans-serif;
}


/* globalLeaders */


.globalLeaders{
  position: relative;
  padding: 200px 0;
  background-image: url('../public/assets/img/New-Project-7.jpg');
  background-position: center;
  background-size: cover;
}
.globalLeaders h2{
  font-weight: 700;
    font-size: 48px;
    color: #FFFFFF!important;
    line-height: 1.2em;
    text-shadow: 0.03em 0.03em 0.05em rgba(0,0,0,0.4);
    padding-left: 25px;
    border-left: 7px solid #4629d2;
    margin-bottom: 20px;
}
.globalLeaders .btn-cs{
  color: #FFF!important;
  border: 2px solid #fff;
  border-radius: 30px;
  font-size: 22px;
  font-weight: 700!important;
  text-transform: uppercase!important;
  background-image: linear-gradient(90deg,#004a55 0%,#4629d2 100%);
  transition: all .3s ease-in-out;
}
.globalLeaders .btn-cs:hover{
  border: 2px solid #fff;
  background: transparent;
  transition: all .3s ease-in-out;
}
.divider-2{
  position: absolute;
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAwJSIgaGVpZ2h0PSIxMDBweCIgdmlld0JveD0iMCAwIDEyODAgMTQwIiBwcmVzZXJ2ZUFzcGVjdFJhdGlvPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPjxnIGZpbGw9IiNmOWY5ZjkiPjxwYXRoIGQ9Ik05NzguODEgMTIyLjI1TDAgMGgxMjgwbC0yNjIuMSAxMTYuMjZhNzMuMjkgNzMuMjkgMCAwIDEtMzkuMDkgNS45OXoiIGZpbGwtb3BhY2l0eT0iLjUiLz48cGF0aCBkPSJNOTgzLjE5IDk1LjIzTDAgMGgxMjgwbC0yNjYgOTEuNTJhNzIuNTggNzIuNTggMCAwIDEtMzAuODEgMy43MXoiLz48L2c+PC9zdmc+);
  background-size: 100% 100px;
  top: 0;
  height: 100px;
  z-index: 1;
  transform: scale(-1,1);
  left: 0;
  width: 100%;
}

.divider-3{
  position: absolute;
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAwJSIgaGVpZ2h0PSIxMDBweCIgdmlld0JveD0iMCAwIDEyODAgMTQwIiBwcmVzZXJ2ZUFzcGVjdFJhdGlvPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPjxnIGZpbGw9IiNmZmZmZmYiPjxwYXRoIGQ9Ik0xMjgwIDBsLTI2NiA5MS41MmE3Mi41OSA3Mi41OSAwIDAgMS0zMC43NiAzLjcxTDAgMHYxNDBoMTI4MHoiIGZpbGwtb3BhY2l0eT0iLjUiLz48cGF0aCBkPSJNMTI4MCAwbC0yNjIuMSAxMTYuMjZhNzMuMjkgNzMuMjkgMCAwIDEtMzkuMDkgNkwwIDB2MTQwaDEyODB6Ii8+PC9nPjwvc3ZnPg==);
  background-size: 100% 100px;
  bottom: 0;
  height: 100px;
  z-index: 1;
  transform: scale(1,1);
  left: 0;
  width: 100%;
}

/* HowWorks */

.HowWorks{
  padding: 100px 0;
}

.HowWorks h2{
  font-weight: 700;
  font-size: 51px;
  margin-bottom: 40px;
}

.HowWorks p{
  font-size: 16px;
  font-weight: 500;
  font-family: 'Source Sans 3', sans-serif;
}


/* getintouch */

.getintouch{
  padding: 100px 0;
  background-image: linear-gradient(152deg,rgba(0,74,85,0.93) 33%,#4629d2 100%)!important;
}

.getintouch form{
  max-width: 600px;
  margin: auto;
  padding: 40px;
  background-color: rgba(255,255,255,0.05);
  border-radius: 50px;
}
 .getintouch .cs-btn-form{
  color: #FFF!important;
  border: 2px solid #fff;
  border-radius: 30px;
  font-size: 22px;
  font-weight: 700!important;
  text-transform: uppercase!important;
  background-image: linear-gradient(90deg,#004a55 0%,#4629d2 100%);
  transition: all .3s ease-in-out;
 }
 .getintouch .cs-btn-form:hover{
  border: 2px solid #fff;
  background: transparent;
  transition: all .3s ease-in-out;
}
.getintouch  form input,
.getintouch  form textarea{
  border: 0;
  background: none;
  border-bottom: 1px solid #fff;
  border-radius: 0;
  margin: 10px 0;
  font-family: 'Source Sans 3', sans-serif;
  color: #fff;
}
.getintouch  form input:focus,
.getintouch  form textarea:focus{
  background: transparent;
  color: #fff;
}
.getintouch form input::-webkit-input-placeholder,
.getintouch form textarea::-webkit-input-placeholder{
  color: #fff;
  opacity: 1;
  
}
.getintouch form input::-moz-placeholder,
.getintouch form textarea::-moz-placeholder{
  color: #ededed;
  opacity: 1;
  
}
.getintouch form input:-ms-input-placeholder,
.getintouch form textarea:-ms-input-placeholder{
  color: #ededed;
  opacity: 1;
  
}
.getintouch form input::-ms-input-placeholder,
.getintouch form textarea::-ms-input-placeholder{
  color: #ededed;
  opacity: 1;
  
}
.getintouch form input::placeholder,
.getintouch form textarea::placeholder{
  color: #ededed;
  opacity: 1;
  
}

.getintouch h2{
  font-weight: 500;
  font-size: 50px;
  color: #FFF!important;
  line-height: 1.2em;
  text-align: center;
}
.getintouch p{
  font-size: 24px;
  color: #FFF!important;
  text-align: center;
  margin-bottom: 50px;
}
/* footer */


footer .footer-link{
  max-width: max-content;
  margin: auto;
}

footer .footer-link a{
  text-decoration: none;
    padding: 10px 20px;
    font-size: 18px;
    color: #000000!important;
    font-family: 'Source Sans 3', sans-serif;
}
.f-col{
  padding: 50px 0;
  background: #e0e0e0!important;
}
.copyright{
  padding: 25px 0;
  background-color: #004a55!important;
}
.copyright p{
  margin: 0;
}

.copyright svg{
  font-size: 40px;
  margin: 0 5px;
}
.navbar-toggler-icon{
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255,255,255, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E") !important;
}
.bg-scroll .navbar-toggler-icon{
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(70,41,210, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E") !important;
}
.navbar-toggler.collapsed{
  border-color: #fff!important;
}
.bg-scroll .navbar-toggler.collapsed{
  border-color: #4629d2!important;
}

.container{
  overflow: hidden;
}

@media only screen and (max-width: 1199px) {
  .video-bg video {
    width: 150%;
    left: 50%;
    position: relative;
    transform: translateX(-50%);
  }
}


@media only screen and (max-width: 991px) {
  .heroSection .row {
    min-height: 550px;
  }
  .ourbusiness {
    padding: 50px 0;
}
.ourbusiness h2 {
  font-size: 40px;
}
.WhoWeAre {
  padding: 50px 0;
}
.WhoWeAre h2 {
  font-size: 25px;
}
.globalLeaders h2{
  font-size: 30px;
}
.HowWorks {
  padding: 50px 0;
}
.HowWorks h2 {
  font-size: 40px;
}
.getintouch {
  padding: 50px 0;
}
}


@media only screen and (max-width: 767px) {
  .heroSection h1 {
    font-size: 40px;
}
.hero p {
  font-size: 20px;
}
.heroSection .row {
  min-height: 400px;
}
.video-bg video {
  width: 280%;
}
.ourbusiness h2 {
  font-size: 25px;
}
.ourbusiness {
  padding: 25px 0;
}
.cta h2 {
  font-size: 24px;
  text-align: center !important;
}
.HowWorks {
  padding: 25px 0;
}
.getintouch h2 {
  font-size: 30px;
}
.getintouch p {
  font-size: 16px;
  margin-bottom: 25px;
}
.HowWorks h2 {
  font-size: 25px;
}
.footer-logo{
  width: 100%;
}
.copyright p {
  text-align: center !important;
}
}
.offcanvas-title img{
  width: 150px;
}
